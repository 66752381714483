export default class Deferred {
  static $resolve;
  static $reject;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.$resolve = resolve;
      this.$reject = reject;
    });

    this.reject = this.reject.bind(this);
    this.resolve = this.resolve.bind(this);
  }

  resolve(value) {
    return this.$resolve(value);
  }
  reject(reason) {
    return this.$reject(reason);
  }
}
