/*
set sidebar open or close,and some app setting
 */

import { dictionariesApi } from "@/apis/source";
const state = {
  enums: {}
};
const mutations = {
  SET_DICTIONARIES_ENUMS(state, payload) {
    state.enums = payload;
  }
};
const actions = {
  getDictionaries({ commit }) {
    return new Promise(resolve => {
      dictionariesApi().then(res => {
        commit("SET_DICTIONARIES_ENUMS", res);
        resolve(res);
      });
    });
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
