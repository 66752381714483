/*
set sidebar open or close,and some app setting
 */
const state = {
  collapsed: false,
  openKeys: [],
  preOpenKeys: [],
  selectedKeys: [],
  breadcrumbRoutes: []
};
const mutations = {
  TOGGLE_COLLAPSED(state) {
    state.collapsed = !state.collapsed;
    state.openKeys = state.collapsed ? [] : state.preOpenKeys;
  },
  UPDATE_OPEN_KEYS(state, payload) {
    state.openKeys = payload;
  },
  UPDATE_PRE_OPEN_KEYS(state, payload) {
    state.preOpenKeys = payload;
  },
  UPDATE_SELECTED_KEYS(state, payload) {
    state.selectedKeys = payload;
  },
  UPDATE_BREADCRUMB_ROUTES(state, payload) {
    state.breadcrumbRoutes = payload;
  }
};
export default {
  namespaced: true,
  state,
  mutations
};
