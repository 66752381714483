export const basicProps = {
  show: {
    type: Boolean,
    default: false
  },
  imageList: {
    type: Array,
    default: null
  },
  index: {
    type: Number,
    default: 0
  }
};
