import Layout from "@/layout";

/**
 * 路由相关属性说明
 * hidden: 当设置hidden为true时，意思不在sideBars侧边栏中显示
 * mete{
 * title: xxx,  设置sideBars侧边栏名称
 * icon: xxx,  设置ideBars侧边栏图标
 * selectKeys: xxx, 左边
 * }
 */

/*通用routers*/
const currencyRoutes = [
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/ErrorPage/404.vue"),
    hidden: true,
    meta: { title: "404" }
  },
  {
    path: "/error",
    name: "error",
    component: () => import("@/views/ErrorPage/error.vue"),
    hidden: true,
    meta: { title: "error" }
  },
  {
    path: "/",
    name: "Home",
    component: Layout,
    redirect: "/home",
    meta: { title: "首页", icon: "iconshouyeweixuanze" },
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("@/views/home.vue"),
        meta: { title: "首页" }
      }
    ]
  }
];
export default currencyRoutes;
