import { createRouter, createWebHistory } from "vue-router";
import currencyRoutes from "./currencyRoutes";

const router = createRouter({
  history: createWebHistory(),
  routes: currencyRoutes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

// 解决addRoute不能删除动态路由问题
export function resetRouter() {
  const reset = router;
  router.matcher = reset.matcher;
}

export default router;
