import {
  request,
  buildAfterPlugin,
  buildBeforePlugin,
  AjaxMethods
} from "@wlhy-web-lib/ajax";
import {
  InfoCircleFilled,
  CheckCircleFilled,
  CloseCircleFilled
} from "@ant-design/icons-vue";
import { message, Modal } from "ant-design-vue";
import { onUnmounted, ref } from "vue";
import store from "@/store";

import { baseUrl } from "./plugins/baseUrl";
import { metaInit } from "./plugins/metaInit";
import { refreshToken } from "./plugins/refreshToken";
import { defaultMethod } from "./plugins/defaultMethod";
import { defaultRequestHeaders } from "./plugins/requestHeaders";

const getLocal = key => localStorage.getItem(key); // 从本地存储中获取数据信息
const TOKEN_KEY = "default_token_key";

const CodeResultEnum = {
  // 令牌不存在或已失效
  TOKEN_ILLEGAL: "RBAC000",
  // 用户不存在
  USER_NOT_EXITS: "RBAC001",
  // 密码或验证码错误
  PASSWORD_ERROR: "RBAC002",
  // 权限不足，请联系管理员
  PERMISSION_ILLEGAL: "RBAC003"
};

const baseOptions = {
  okText: "确定",
  centered: true
};
function renderContent({ content }) {
  return <div innerHTML={`<div>${content}</div>`}></div>;
}
function getIcon(iconType) {
  if (iconType === "warning") {
    return <InfoCircleFilled class="modal-icon-warning" />;
  } else if (iconType === "success") {
    return <CheckCircleFilled class="modal-icon-success" />;
  } else if (iconType === "info") {
    return <InfoCircleFilled class="modal-icon-info" />;
  } else {
    return <CloseCircleFilled class="modal-icon-error" />;
  }
}
function createModalOptions(options, icon) {
  return {
    ...baseOptions,
    ...options,
    content: renderContent(options),
    icon: getIcon(icon)
  };
}
function createErrorModal(options) {
  return Modal.error(createModalOptions(options, "close"));
}

const plugins = [
  {
    desc: "初始化请求中的 meta 对象：需要在最早初始化",
    order: -10500,
    executor: metaInit
  },
  {
    desc: "初始化请求的请求方法：没有设置时置为 POST",
    order: -10450,
    executor: defaultMethod
  },
  {
    desc: "添加一些默认的请求头：这些请求头通常是实际业务需要的",
    order: -10400,
    executor: defaultRequestHeaders
  },
  { desc: "refreshToken：实际业务需要", order: -10300, executor: refreshToken },
  {
    desc: "baseUrl：添加 Ajax 请求的基础前缀",
    order: -10200,
    executor: baseUrl
  },
  {
    desc: "基础业务前置插件",
    order: -10150,
    executor: buildBeforePlugin({
      apiUrl: "apiUrl",
      urlPrefix: "urlPrefix"
    })
  },
  {
    desc: "基础业务后置插件",
    order: 10050,
    executor: buildAfterPlugin({
      /**
       * 成功的指示状态码
       */
      successCode: "SUCCESS",
      /**
       * 根据不同的错误码和描述信息执行某些操作，属于业务异常的处理都在这里订阅
       */
      checkErrorCode(code, msg, result) {
        console.log(result);
        let onOk = () => {};
        switch (code) {
          case CodeResultEnum.TOKEN_ILLEGAL:
            onOk = () => store.dispatch("user/logout", true);
            msg = "该账号需要重新登录";
            break;
          case CodeResultEnum.PERMISSION_ILLEGAL:
            msg = "没有该功能权限";
            onOk = () => store.dispatch("user/backToHome", true);
            break;
        }
        if (msg) {
          // errorMessageMode=‘modal’的时候会显示modal错误弹窗，而不是消息提示，用于一些比较重要的错误
          createErrorModal({ title: "错误提示", content: msg, onOk });
        }
      },
      /**
       * 根据不同的 HTTP 状态码和描述信息执行某些操作：
       * 额外的特殊状态码，由组件库定义：
       * :timeout => 表示超时
       * :networkError => 表示网络异常
       */
      checkHttpErrorCode(code, msg) {
        if (code === ":networkError") {
          createErrorModal({
            title: "网络异常",
            content: "请检查您的网络连接是否正常!"
          });
          return;
        }

        if (code === "403") {
          store.dispatch("user/_login");
        } else if (code === "302") {
          console.log();
        } else {
          message.error(msg);
        }
      }
    })
  },
  {
    desc: "defaultToken",
    order: -10130,
    executor: (next, config) => {
      const token = getLocal(TOKEN_KEY) || "default_token_value"; // 这里是缺省的 Token：defaultToken
      // jwt token
      if (config.headers) {
        config.headers.Authorization = token;
      } else {
        config.headers = { Authorization: token };
      }

      return next();
    }
  }
];

export const agent$ = request.attach(plugins);

export const buildApi = basicPrefix => (urlOrConfig, ...plugins) => {
  const config =
    typeof urlOrConfig === "string"
      ? { url: `${basicPrefix}${urlOrConfig}` }
      : { ...urlOrConfig, url: `${basicPrefix}${urlOrConfig.url}` };

  return (...plugins2) => {
    const cancelerRef = ref();

    const cancelPlugin = (next, config) => {
      cancelerRef.value = config.meta?.canceler;
      return next();
    };
    onUnmounted(() => {
      if (cancelerRef.value) {
        cancelerRef.value();
      }
    });
    return (data = {}, options, ...plugin3) => {
      const composedConfig =
        config.method === AjaxMethods.GET
          ? { ...config, ...options, params: data }
          : { ...config, ...options, data };
      return request.send(composedConfig, cancelPlugin, [
        ...plugins,
        ...plugins2,
        ...plugin3
      ]);
    };
  };
};

export const buildApi2 = basicPrefix => (urlOrConfig, ...plugins) => {
  const config =
    typeof urlOrConfig === "string"
      ? { url: `${basicPrefix}${urlOrConfig}` }
      : { ...urlOrConfig, url: `${basicPrefix}${urlOrConfig.url}` };

  return (data = {}, options, ...plugin3) => {
    const composedConfig =
      config.method === AjaxMethods.GET
        ? { ...config, ...options, params: data }
        : { ...config, ...options, data };
    return request.send(composedConfig, [...plugins, ...plugin3]);
  };
};
