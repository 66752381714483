<template>
  <w-upload
    :size="size"
    :fileSize="fileSize"
    :fileType="fileType"
    multiple
    :imgObj="initialValue"
    @request="handleUpload"
    @input="handleChanging"
  />
</template>

<script>
import { throttle } from "lodash-es";
import { defineComponent } from "vue";
import { message } from "ant-design-vue";

export default defineComponent({
  name: "p-uploader-trigger",
  props: {
    size: {
      type: Object,
      default: () => {}
    },
    fileSize: {
      type: Number,
      default: 2
    },
    fileType: {
      type: String,
      // 可选有三个：
      // BUSINESS_LICENSE 表示『营业执照』
      // IDCARD_FRONT 表示『身份证正面』
      // IDCARD_BACK 表示『身份证反面』
      default: ""
    }
  },
  setup(props, context) {
    const initialValue = { data: "", error: "", filename: "", loading: false };

    const handleUpload = throttle(
      files => {
        if (files.length > props.fileSize) {
          message.error(`最多可上传${props.fileSize}张`);
          return;
        }

        const beyondMax = files.find(
          file => file.size / 1024 / 1024 > props.fileSize
        );

        !beyondMax &&
          context.emit(
            "selected",
            files.map(v => ({
              data: "",
              error: "",
              filename: v,
              loading: false
            }))
          );
      },
      30,
      { trailing: false }
    );

    const handleChanging = val => {
      if (val.error) {
        message.error(val.error);
      }
    };

    return { initialValue, handleUpload, handleChanging };
  }
});
</script>
