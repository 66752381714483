import { buildApi, buildApi2 } from "@/utils/ajax";

const basicApi = buildApi("/portal/ops");
const basicApi2 = buildApi2("/portal/ops");
// 税源地列表
export const useTaxSourcesApi = basicApi({
  url: "/tax/sources",
  withCredentials: true
});

// 税源地信息
export const useTaxBureausApi = basicApi({
  url: "/tax/bureaus/query",
  withCredentials: true
});

// 新增税源地
export const useTaxSourceSaveApi = basicApi({
  url: "/tax/tax_source/save",
  withCredentials: true
});

// 读取计税字典配置
export const dictionariesApi = basicApi2({
  url: "/tax/dictionaries",
  withCredentials: true
});

// 根据税务局数据标识查询相同的税务局信息
export const useTaxBureauByDataFlagApi = basicApi({
  url: "/tax/bureaus/by_data_flag",
  withCredentials: true
});

// 获取税源地某个税种的配置信息
export const useTaxRateConfigApi = basicApi({
  url: "/tax/rate_config/single/query",
  withCredentials: true
});

// 保存预览
export const useTaxSavePreviewApi = basicApi({
  url: "/tax/save/preview",
  withCredentials: true
});

// 税源地配置的财税信息
export const useTaxRateConfigQueryApi = basicApi({
  url: "/tax/rate_config/query",
  withCredentials: true
});
// 删除税源地关联的某个税务局
export const useTaxAlarmConfigApi = basicApi({
  url: "/tax/alarm_config/query",
  withCredentials: true
});

// 读取某个税源地配置的预警信息
export const useDeleteTaxBureauApi = basicApi({
  url: "/tax/delete/tax_bureau",
  withCredentials: true
});
// 税源地绑定税务局关系
export const useTaxBindingBureauApi = basicApi({
  url: "/tax/binding/bureau",
  withCredentials: true
});
// 税源地修税务局信息
export const useTaxUpdateBureauApi = basicApi({
  url: "/tax/bureau/update",
  withCredentials: true
});

// 税源地新增预警信息
export const useTaxAlarmConfigSaveApi = basicApi({
  url: "/tax/alarm_config/save",
  withCredentials: true
});
// 税源地编辑某条预警信息
export const useTaxAlarmConfigUpdateApi = basicApi({
  url: "/tax/alarm_config/update",
  withCredentials: true
});

// 税源地删除某条预警信息
export const useTaxAlarmDeleteApi = basicApi({
  url: "/tax/alarm_config/delete",
  withCredentials: true
});

// 编辑税种的配置信息
export const useTaxRateConfigUpdateApi = basicApi({
  url: "/tax/rate_config/update",
  withCredentials: true
});
// 获取税源地某个税种的配置信息
export const useTaxRateConfigSingleQueryApi = basicApi({
  url: "/tax/rate_config/single/query",
  withCredentials: true
});
