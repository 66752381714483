/**
 * 用途是将 Axios 的 CancelToken 向外传递，用于在特定的时机执行 cancel 操作
 * controllerRef 是 ref 类型的数据
 */
export const manualCanceler = controllerRef => (next, config) => {
  if (!config.cancelToken) {
    const token = window.Axios.default.CancelToken.source();

    config.cancelToken = token.token;
    controllerRef.value = token;

    const handleAfterNext = v => {
      controllerRef.value = null;

      return v;
    };
    return next().then(handleAfterNext, handleAfterNext);
  }

  return next();
};
