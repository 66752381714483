import { resetRouter } from "@/router";
// import { message } from "ant-design-vue";
import { getUserInfoApi } from "@/apis/user";
import useDebounce from "@/hooks/useDebounce";

const path =
  process.env.NODE_ENV === "production"
    ? process.env.VUE_APP_PATH
    : process.env.VUE_APP_BASEURL;

const state = {
  token: localStorage.getItem("token") ? localStorage.getItem("token") : "", // 认证凭证'
  userName: "",
  roles: [],
  introduce: ""
};
const mutations = {
  SET_TOKEN(state, val) {
    state.token = val;
    localStorage.setItem("token", val);
  },
  DEL_TOKEN(state) {
    state.token = "";
    state.userName = "";
    state.userInfo = "";
    state.roles = "";
    state.introduce = "";
    localStorage.removeItem("token");
  },
  SET_ROLES(state, payload) {
    state.roles = payload;
  },
  SET_NAME(state, payload) {
    state.userName = payload;
  },
  SET_USER_INFO(state, payload) {
    state.userInfo = payload;
  },
  SET_INTRODUCE(state, payload) {
    state.introduce = payload;
  }
};
const actions = {
  // user login
  _login: useDebounce(() => {
    resetRouter();
    // message.error("登录已失效，请重新登录！");
    window.location.href =
      path +
      "/portal/ops/login?redirect_url=" +
      encodeURIComponent(window.location.href);
  }, 500),
  _loginOut() {
    resetRouter();
    window.location.href =
      path +
      "/portal/ops/logout?redirect_url=" +
      encodeURIComponent(window.location.origin);
  },
  _getUserInfo({ commit }) {
    return getUserInfoApi().then(res => {
      const roles = [...res.menus, "*404"];
      if (res.user.username) {
        commit("SET_NAME", res.user.name);
        commit("SET_USER_INFO", res.user);
        commit("SET_ROLES", roles);
        localStorage.setItem("roles", JSON.stringify(roles));
      }
      return roles;
    });
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
