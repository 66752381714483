<template>
  <div id="app">
    <div
      ref="pop-container"
      style="position: fixed; top: 0; left: 0; z-index: 1000; width: 0"
    />
    <ConfigProvider :locale="locale" :getPopupContainer="getPopupContainer">
      <router-view v-slot="{ Component }">
        <transition name="fade-slide" mode="out-in" appear>
          <component :is="Component" />
        </transition>
      </router-view>
    </ConfigProvider>
  </div>
</template>

<script lang="ts">
import { ConfigProvider } from "ant-design-vue";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";

export default {
  components: { ConfigProvider },
  beforeMount() {
    this.locale = {
      ...zhCN,
      Pagination: {
        ...zhCN.Pagination,
        items_per_page: "条/页",
        jump_to: "跳转至：",
        page: "页"
      }
    };
  },
  methods: {
    getPopupContainer() {
      return this.$refs["pop-container"] || document.body;
    }
  }
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>
