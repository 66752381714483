import { buildApi, buildApi2 } from "@/utils/ajax";
import Deferred from "./deferred";
import { netService } from "@wlhy-web-lib/spa";
import { seqence } from "./plugins/seqence";
// import { flatten, uniq } from "lodash-es";
import { ref, onUnmounted } from "vue";

import { dataConverter } from "./plugins/data-converter";
// import { concatRequest } from "./plugins/concat-request";
// import { filenameCache } from "./plugins/filename-cache";
import { manualCanceler } from "@/api/plugins/manual-canceler";

const basicApi = buildApi("/portal/ops");
const basicApi1 = buildApi2("/portal/ops");

const UPLOAD_API = "/oss/upload";
const FILE_URL_MULT_API = "/oss/generate_url";

const seq = seqence(3);

export const useUploadFileApi = (...plugins) => {
  return basicApi(
    {
      url: UPLOAD_API,
      withCredentials: true,
      meta: { upload: true }
    },
    plugins
  );
};

export const uploadFileApi = (body, ...plugins) => {
  return basicApi(
    {
      url: UPLOAD_API,
      withCredentials: true,
      body,
      meta: { form: true }
    },
    plugins
  );
};

// const concatPlugin = concatRequest(
//   configs => ({
//     data: {
//       filenames: uniq(flatten(configs.map(v => v.data.filenames)))
//     }
//   }),
//   config => config.data.filenames,
//   (mapper, filenames) =>
//     filenames.reduce((acc, x) => ({ ...acc, [x]: mapper[x] }), {})
// );

// 下面这段为 Mock 代码，对接正式接口后会删掉
export const mockResponse = files => {
  return new Promise(r => {
    setTimeout(
      () =>
        r(
          files.reduce((acc, x) => {
            acc[
              x
            ] = `https://n.sinaimg.cn/spider20201124/756/w939h617/20201124/ada9-kefmphc9293017.png`;
            return acc;
          }, {})
        ),
      100
    );
  });
};

const filenamesConvert = data => {
  // 只传了一个参数的情况
  if (typeof data === "string") {
    return { filenames: [data] };
  }
  console.log({ filename: data });
  // 传了一个数组的情况
  return { filename: data };
};

/**
 *
 * @param  {...any} plugins
 * 同上功能，扩展了参数，cancelToken抛出便于控制
 */
export const useUploadControlFileApi = (...plugins) => {
  // 用于控制 Ajax 请求取消的对象
  const cancelTokenRef = ref(null);
  const cancel = () => {
    const { value } = cancelTokenRef;
    if (value) {
      value.cancel();
      cancelTokenRef.value = null;
    }
  };
  onUnmounted(() => {
    cancel();
  });
  const uploadApi = (body, options = {}) =>
    netService.post(
      UPLOAD_API,
      {
        body,
        ...options,
        meta: {
          upload: true,
          converter(data) {
            return { file: data };
          }
        }
      },
      manualCanceler(cancelTokenRef),
      {
        desc: "数据格式转换插件：需要在 defaultBeforePlugin 插件之前运行",
        order: -10180,
        executor: dataConverter
      },
      seq,
      ...plugins
    );
  return {
    uploadApi,
    cancel
  };
};

export const useFetchFileUrlApi = () => {
  // 用于控制取消时订阅的 Promise 控制对象
  const defer = new Deferred();
  // 用于控制 Ajax 请求取消的对象
  const cancelTokenRef = ref(null);

  // 组件销毁时取消请求
  onUnmounted(() => {
    const { value } = cancelTokenRef;

    if (value) {
      value.cancel();
      defer.reject();
      cancelTokenRef.value = null;
    }
  });
  return basicApi1(
    {
      url: FILE_URL_MULT_API,
      withCredentials: true,
      meta: {
        converter: filenamesConvert
      }
    },
    manualCanceler(cancelTokenRef),
    dataConverter
    // concatPlugin(defer.promise),
    // filenameCache(defer.promise)
  );
};

// const neverCancelPromise = new Promise(() => {});
export const fetchFileUrlApi = (body, ...plugins) =>
  basicApi(
    {
      url: FILE_URL_MULT_API,
      body,
      withCredentials: true,
      meta: {
        converter: filenamesConvert
      }
    },
    dataConverter,
    // concatPlugin(neverCancelPromise),
    // filenameCache(neverCancelPromise),
    ...plugins
  );
