import "xe-utils";
import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import "./router/beforeEach";
// import * as Sentry from "@sentry/browser";
// import { Integrations } from "@sentry/tracing";

/**
 * @name 统一注册插件、样式
 */
import "ant-design-vue/dist/antd.css";
import "./styles/antd-theme.less";
// import "@wlhy-web-components/common/lib/lib/index.css";
import "@/styles/index.less"; // glob less
import "vxe-table/lib/style.css";
import "@wlhy-web-styles/theme-pc-s";

import animated from "animate.css";
import VXETable from "vxe-table";
import Components from "@wlhy-web-components/common";
import install from "./utils/install";

import "@/utils/sentry/index";

const app = createApp(App)
  .use(store)
  .use(router)
  .use(animated)
  .use(VXETable)
  .use(Components);

install(app);

app.mount("#app");

// 监控系统
// process.env.VUE_APP_SENTRY &&
//   Sentry.init({
//     Vue: app,
//     dsn: "https://17b6c67fe5e846c5b63b9dc2a9dd17c3@sentry.wlhyos.com/5",
//     autoSessionTracking: true,
//     integrations: [new Integrations.BrowserTracing()],

//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0
//   });
