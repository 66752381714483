import { watch } from "vue";

import convert from "@/utils/object-converter";

const toFilename = obj => obj.filename;
const fromFilename = filename => ({
  filename,
  data: "",
  error: "",
  loading: false
});

export const toDataFilename = convert(toFilename);
export const fromDataFilename = convert(fromFilename);
export const toDataFilenameList = list => list.map(toFilename);
export const fromDataFilenameList = list => list.map(fromFilename);

export const useBatchPictures = (picturesRef, fileListName) => {
  watch(
    () => picturesRef.value[fileListName],
    list => {
      if (list.some(v => !v.filename)) {
        picturesRef.value[fileListName] = list.filter(v => v.filename);
      }
    }
  );
};
