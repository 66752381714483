import "xe-utils";
import { isUnDef, isFunction, isArray } from "@wlhy-web-lib/shared";
import VXETable from "vxe-table";
import Components, { Searcher, Table } from "@wlhy-web-components/common";
import {
  Form,
  Input,
  Button,
  Popconfirm,
  Drawer,
  InputNumber,
  Select,
  DatePicker,
  Statistic,
  Spin
} from "ant-design-vue";
import PictureUploader, {
  Trigger as PictureUploaderTrigger
} from "@/components/PictureUploader";
import PDatePicker from "@/components/DatePicker";
import Icon from "@/components/Icon";

import "../ajax";

import "vxe-table/lib/style.css";

const components = [Icon];

const install = app => {
  const componentsInstall = app => {
    if (componentsInstall.installed) return;
    components.map(component => app.component(component.name, component));
  };

  const installObj = {
    install: componentsInstall,
    ...components
  };

  app
    .use(installObj)
    .use(Components)
    .use(Form)
    .use(Button)
    .use(Spin)
    .use(Input)
    .use(VXETable)
    .use(Popconfirm)
    .use(Drawer)
    .use(InputNumber)
    .use(Select)
    .use(DatePicker)
    .use(Statistic)
    .use(components)
    .component(PictureUploader.name, PictureUploader)
    .component(PDatePicker.name, PDatePicker)
    .component(PictureUploaderTrigger.name, PictureUploaderTrigger);

  Searcher.extendComponent("dateRange", "dateRange", {
    defaultValue: {}
  });

  Searcher.extendComponent("select", "select-search", {
    getRefreshList(config) {
      let defaultValue;
      if (isFunction(this.defaultValue)) {
        defaultValue = this.defaultValue(config);
      } else {
        defaultValue = this.defaultValue;
      }

      const refreshList = defaultValue.refreshList;
      return isUnDef(config.refreshList) ? refreshList : config.refreshList;
    },

    // 获取渲染时绑定的 events
    getBindingEvents(dataSourceRef, config) {
      return {
        change(value) {
          dataSourceRef.value[config.dataKey] = value;
        }
      };
    },
    // 获取绑定时的 props
    getBindingProps(dataSourceRef, config) {
      const refresh = this.getRefreshList(config);
      const currentOptions = config.options || [];
      return {
        value: dataSourceRef.value[config.dataKey],
        options: refresh ? refresh.exec(currentOptions) : currentOptions,
        showSearch: true,
        filterOption: (input, option) => {
          return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
        defaultActiveFirstOption: true,
        ...config.props
      };
    }
  });

  Searcher.extendComponent("select", "select-data-search", {
    getRefreshList(config) {
      let defaultValue;
      if (isFunction(this.defaultValue)) {
        defaultValue = this.defaultValue(config);
      } else {
        defaultValue = this.defaultValue;
      }

      const refreshList = defaultValue.refreshList;
      return isUnDef(config.refreshList) ? refreshList : config.refreshList;
    },

    // 获取渲染时绑定的 events
    getBindingEvents(dataSourceRef, config) {
      return {
        change(value) {
          dataSourceRef.value[config.dataKey] = value;
        },
        search(value) {
          config.props.onSearch(value);
        },
        select() {
          // 选择的时候将列表重置
          config.props.onSearch();
        }
      };
    },

    // 获取绑定时的 props
    getBindingProps(dataSourceRef, config) {
      const refresh = this.getRefreshList(config);
      const currentOptions = config.options || [];
      return {
        value: dataSourceRef.value[config.dataKey],
        options: refresh ? refresh.exec(currentOptions) : currentOptions,
        showSearch: true,
        // filterOption: (input, option) => {
        //   // console.log(option.children.default()[0]);
        //   // return currentOptions.filter(item => {
        //   //   return item.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        //   // });
        //   return (
        //     option.children
        //       .default()[0]
        //       .toLowerCase()
        //       .indexOf(input.toLowerCase()) >= 0
        //   );
        // },
        filterOption: (input, option) => {
          return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
        defaultActiveFirstOption: true,
        ...config.props
      };
    }
  });
};

Table.setColumnInterceptor(() => [
  {
    label: "null-render", // 在渲染结果为 null 的时候，统一渲染为 -
    value(next) {
      const rendered = next();

      if (
        !rendered || // 渲染为某些对象的时候的判断
        (typeof rendered === "string" && rendered.trim() === "-") || // 渲染为字符串的判断
        (isArray(rendered) && // 渲染是数组的判断
          (!rendered.length || // 空数组需要替换
            rendered.every(
              v =>
                v === null ||
                v === undefined ||
                (typeof v === "string" && v.trim() === "")
            ))) // 数组中的元素全是 false 需要替换，但数字 0 不替换
      ) {
        return ["-"];
      }

      // 其它所有情况不替换
      return rendered;
    }
  }
]);

console.log(Searcher);

export default install;
