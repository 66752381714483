// 导航守卫
import router from "@/router";
import store from "@/store";
import staticRoutes from "./staticRoutes";
import currencyRoutes from "@/router/currencyRoutes";

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;
  const hasRoles = store.getters.roles.length > 0;
  // 有权限直接跳转
  if (hasRoles) {
    const firstPath = `/${to.fullPath.split("/")[1]}`;
    const secondPath = `${to.fullPath.split("/")[2]?.split("?")[0]}`;
    const openKeys = firstPath;

    // 解决详情页左侧栏不高亮问题
    const selectPath = to.meta.selectKeys
      ? `${firstPath}/${to.meta.selectKeys}`
      : `${firstPath}/${secondPath}`;

    // 如果是首页，path为 '/'特殊处理
    const selectedKeys = to.fullPath === "/home" ? "/" : selectPath;
    store.commit("app/UPDATE_OPEN_KEYS", [openKeys]);
    store.commit("app/UPDATE_SELECTED_KEYS", [selectedKeys]);

    getBreadcrumbRoutes(to.fullPath);

    next();
    // 无权限去获取权限列表
  } else {
    try {
      let roles = currencyRoutes;
      // 如果是静态路由，不调用接口
      if (!~staticRoutes.indexOf(to.name)) {
        roles = await store.dispatch("user/_getUserInfo");

        const addRoutes = await store.dispatch(
          "permission/getAsyncRoutes",
          roles
        );
        addRoutes.forEach(item => {
          router.addRoute(item);
        });
        // hack method to ensure that addRoutes is complete
        // set the replace: true, so the navigation will not leave a history record
        next({ ...to, replace: true });
      } else {
        next();
      }
    } catch (error) {
      // message.error(error);
    }
  }
});

// 获取面包屑路由数组
const getBreadcrumbRoutes = path => {
  path = path.split("?")[0];
  let paths = path.split("/");
  paths.splice(0, 1);

  const routes = JSON.parse(JSON.stringify(store.getters.routes));

  let breadcrumbRoutes = [];

  // 递归筛选当前路由
  const reduceRoutes = routes => {
    routes.forEach(item => {
      // 首页
      if (item.path === "/") {
        item.children = [];
        breadcrumbRoutes.push(item);
      }
      // 非首页
      if (item.path.split("/").pop() === paths[0]) {
        const children = item.children;
        item.children = [];
        breadcrumbRoutes.push(item);
        paths.splice(0, 1);
        if (paths.length) {
          reduceRoutes(children);
        }
      }
    });
  };

  reduceRoutes(routes);

  store.commit("app/UPDATE_BREADCRUMB_ROUTES", breadcrumbRoutes);
};
