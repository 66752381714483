<template>
  <Content class="content">
    <router-view v-slot="{ Component }">
      <transition name="fade-page" mode="out-in">
        <keep-alive
          v-if="
            $route.meta.keepAlive &&
              !Object.getOwnPropertyNames($route.query).length
          "
        >
          <component :is="Component" />
        </keep-alive>
        <component :is="Component" v-else />
      </transition>
    </router-view>
  </Content>
</template>

<script>
import { Layout } from "ant-design-vue";

export default {
  components: {
    Content: Layout.Content
  },
  setup() {
    return {};
  }
};
</script>

<style lang="less" scoped>
.content {
  transition: all 0.3s;
  margin-top: 64px;
  height: calc(~"100vh - 64px");
  overflow: auto;
}
</style>
