<template>
  <Header class="head-layout">
    <div class="head-left flex">
      <MenuUnfoldOutlined
        v-if="$store.state.app.collapsed"
        class="trigger"
        @click="toggleCollapsed"
      />
      <MenuFoldOutlined v-else class="trigger" @click="toggleCollapsed" />

      <!-- 面包屑 -->
      <Breadcrumb :routes="$store.state.app.breadcrumbRoutes">
        <template #itemRender="{ route }">
          <span>
            {{ route.meta.title }}
          </span>
        </template>
      </Breadcrumb>
    </div>

    <div class="head-right flex" v-if="$store.state.user.userName">
      <Icon name="iconwode"></Icon>
      <p class="user-name">{{ $store.state.user.userName }}</p>

      <Icon
        name="icontuichubeifen"
        class="icon-loginout"
        @click="handleClick"
      ></Icon>
    </div>
    <div v-else>
      <a-button
        class="mr-24"
        type="primary"
        @click="$store.dispatch('user/_login')"
        >登录</a-button
      >
    </div>

    <w-modal
      :w="288"
      ref="modal"
      :closable="false"
      @handleOk="handleLoginOut"
      centered
      okText="退出"
      cancelText="取消"
    >
      <div>
        <div class="d-flex justify-center">
          <Icon name="iconGroup282" height="16" width="16"></Icon>
          <p class="fz-16 fw-600 ml-8 lh-18">提示</p>
        </div>
        <p class="text-center mt-16">您是否确定退出当前账号</p>
      </div>
      <template v-slot:footer="{ okText, handleOk, handleCancel, cancelText }">
        <a-button @click="handleOk">{{ okText }}</a-button>
        <a-button type="primary" @click="handleCancel">{{
          cancelText
        }}</a-button>
      </template>
    </w-modal>
  </Header>
</template>

<script>
import { ref, watch, computed } from "vue";
import { Layout, Breadcrumb } from "ant-design-vue";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";

export default {
  components: {
    Header: Layout.Header,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    Breadcrumb
  },
  setup() {
    const store = useStore();
    const openKeys = computed(() => store.state.app.openKeys);

    const modalRef = ref();

    watch(
      () => openKeys.value,
      (val, oldVal) => {
        store.commit("app/UPDATE_PRE_OPEN_KEYS", oldVal);
      },
      {
        deep: true
      }
    );

    const handleClick = () => {
      modalRef.value.initShow();
    };

    const toggleCollapsed = () => {
      store.commit("app/TOGGLE_COLLAPSED");
    };

    // 登出
    const handleLoginOut = () => {
      store.dispatch("user/_loginOut");
    };

    return {
      openKeys,
      toggleCollapsed,
      modal: modalRef,
      handleClick,
      handleLoginOut
    };
  }
};
</script>

<style lang="less">
.head-layout {
  background: #fff !important;
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  position: fixed;
  z-index: 10;
  width: calc(~"100% - 200px");
  .trigger {
    margin-top: 8px;
  }
  .flex {
    display: flex;

    align-items: center;
  }
  .icon-loginout {
    margin-right: 18px;
    cursor: pointer;
  }
  .user-name {
    margin: 0 16px 0 8px;
  }
}
.ant-layout-sider-collapsed ~ .ant-layout {
  .head-layout {
    width: calc(100% - 80px);
  }
}
</style>
