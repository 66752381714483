<template>
  <Sider
    v-model:collapsed="$store.state.app.collapsed"
    :trigger="null"
    collapsible
  >
    <div class="logo">
      <span v-if="!$store.state.app.collapsed"
        >小快科技服务市场<br />运营管理后台</span
      >
    </div>
    <Menu
      theme="dark"
      mode="inline"
      v-model:selectedKeys="$store.state.app.selectedKeys"
      :openKeys="$store.state.app.openKeys"
      :inline-collapsed="$store.state.app.collapsed"
      @openChange="handleOpenChange"
      @click="handleRoutePush"
    >
      <template v-for="item in routes" :key="item.key">
        <!-- 有且不仅有一个自路由 -->
        <Sub-menu v-if="item.children && item.name !== 'Home'" :key="item.key">
          <template #title>
            <span class="d-flex">
              <Icon
                :name="item.meta.icon"
                class="mt-10 mr-8 anticon"
                height="16"
                width="16"
              ></Icon>
              <span>{{ item.meta.title }}</span>
            </span>
          </template>
          <template v-for="child in item.children" :key="child.key">
            <Menu-item v-if="!child.hidden" :key="child.key">
              {{ child.meta.title }}
            </Menu-item>
          </template>
        </Sub-menu>
        <!-- 只有一个自路由 -->
        <Menu-item v-else :key="item.key">
          <Icon
            :name="item.meta.icon"
            class="mt-10 mr-8 anticon"
            height="16"
            width="16"
          ></Icon>
          <span>{{ item.meta.title }}</span>
        </Menu-item>
      </template>
    </Menu>
  </Sider>
</template>

<script>
import { ref, computed } from "vue";
import { Layout, Menu } from "ant-design-vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  components: {
    Sider: Layout.Sider,
    Menu,
    "Menu-item": Menu.Item,
    "Sub-menu": Menu.SubMenu
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    // 一级菜单栏
    const rootSubmenuKeys = ref([]);

    // 菜单栏路由列表
    const routes = computed(() => {
      let filterArray = [];
      store.getters.routes.forEach(item => {
        if (!item.hidden) {
          if (item.children && item.children.length > 0) {
            item.key = item.path;
            rootSubmenuKeys.value.push(item.key);
            item.children.map(child => {
              child.key = `${item.path}/${child.path}`;
              return child;
            });
          } else {
            item.key = item.path;
          }
          filterArray.push(item);
        }
      });
      return filterArray;
    });

    // 一级菜单状态改变
    const handleOpenChange = openKeys => {
      const latestOpenKey = openKeys.find(
        key => store.state.app.openKeys.indexOf(key) === -1
      );
      if (rootSubmenuKeys.value.indexOf(latestOpenKey) === -1) {
        store.commit("app/UPDATE_OPEN_KEYS", openKeys);
      } else {
        store.commit(
          "app/UPDATE_OPEN_KEYS",
          latestOpenKey ? [latestOpenKey] : []
        );
      }
    };

    // 路由跳转
    const handleRoutePush = item => {
      router.push(item.key);
    };

    return {
      routes,
      handleOpenChange,
      handleRoutePush
    };
  }
};
</script>
