<template>
  <w-upload
    :size="size"
    :fileSize="fileSize"
    :fileType="fileType"
    :disabled="disabled"
    :imgObj="currentValue"
    @input="handleChanging"
    @request="handleUpload"
    @preview="handlePreview"
    :loadingMessage="loadingMessage"
    v-if="!readonly"
  />
  <p-image
    v-else
    :style="{ width: size.w, height: size.h }"
    :src="value.data"
  />
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted,
  onUpdated,
  computed,
  watch
} from "vue";

// import tool from '@/utils/tool';
import { useFetchFileUrlApi, useUploadFileApi } from "@/api/upload";
import { createImgPreview } from "../Preview/index";

export default defineComponent({
  name: "p-uploader",
  props: {
    size: {
      type: Object,
      default: () => ({ w: "228px", h: "128px" })
    },
    fileSize: {
      type: Number,
      default: 2
    },
    fileType: {
      type: String
      // 可选有三个：
      // BUSINESS_LICENSE 表示『营业执照』
      // IDCARD_FRONT 表示『身份证正面』
      // IDCARD_BACK 表示『身份证反面』
      // default: "BUSINESS_LICENSE"
    },
    value: {
      type: Object,
      default: () => ({
        data: "", // 图片的临时 URL
        error: "", // 发生错误后的异常日志字符串
        filename: "", // 图片上传后返回的真实 Filename 路径
        loading: false // 用于指示图片是否正在上传中
      })
    },
    disabled: {
      type: Boolean,
      default: false
    },
    //是否只读;用于回显
    readonly: {
      default: false
    }
  },
  setup(props, context) {
    const isFetchingRef = ref(false);
    const loadingMessageRef = ref("");
    const fetchUrl = useFetchFileUrlApi();
    const uploadPicture = useUploadFileApi()();
    const currentValue = computed(() => ({
      ...props.value,
      loading: isFetchingRef.value || props.value.loading
    }));

    const handleChanging = val => context.emit("input", val);
    const handlePreview = () => {
      createImgPreview({ imageList: [props.value.data] });
    };

    const handleFetchUrl = () => {
      const { filename, data, error } = props.value;

      if (
        filename &&
        typeof filename === "string" &&
        !data &&
        !error &&
        !isFetchingRef.value
      ) {
        const token = setTimeout(() => (isFetchingRef.value = true), 300);

        fetchUrl(filename).then(
          res => {
            clearTimeout(token);
            isFetchingRef.value = false;

            context.emit("input", {
              ...props.value,
              data: res[filename],
              loading: false
            });
          },
          () => {
            clearTimeout(token);
            isFetchingRef.value = false;
          }
        );
      }
    };
    onMounted(handleFetchUrl);
    onUpdated(handleFetchUrl);

    const handleUpload = file => {
      const token = setTimeout(() => {
        loadingMessageRef.value = "正在上传图片";
        context.emit("input", { ...props.value, loading: true });
      }, 300);

      return uploadPicture({ file }).then(
        ({ filename, data, expire_time }) => {
          clearTimeout(token);
          loadingMessageRef.value = "";

          context.emit("input", {
            filename,
            data,
            expire_time,
            error: "",
            loading: false
          });

          //上传图片成功,向外抛出事件,可用于ocr校验
          context.emit("uploaded", {
            filename,
            data,
            expire_time,
            error: "",
            loading: false
          });
        },
        () =>
          context.emit("input", {
            filename: "",
            data: "",
            error: "图片上传发生错误",
            loading: false
          })
      );
    };

    // 如果 value.filename 是文件选择的 File 实例则表示需要上传，同时需要显示本地图片
    watch(
      () => props.value.filename,
      filename => {
        if (filename && typeof filename !== "string" && !props.value.data) {
          const localUrl = URL.createObjectURL(filename);

          // eslint-disable-next-line vue/no-mutating-props
          props.value.data = localUrl;
          // eslint-disable-next-line vue/no-mutating-props
          props.value.loading = true;

          handleUpload(filename).then(() => {
            URL.revokeObjectURL(localUrl);
          });
        }
      },
      { immediate: true }
    );

    return {
      currentValue,
      handleUpload,
      handlePreview,
      handleChanging,
      loadingMessage: loadingMessageRef
    };
  }
});
</script>
