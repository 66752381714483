<template>
  <a-date-picker
    :value="momentValue"
    @change="handleChange"
    :disabled-date="disabledDate || handleDisableDate"
    v-bind="$attrs"
    :disabled-time="handleDisabledDateTime"
  />
</template>
<script>
import { defineComponent, computed } from "vue";
import moment from "moment";

export default defineComponent({
  name: "p-date-picker",
  props: {
    value: {
      type: Number || null,
      required: true
    },
    disableType: {
      // 禁用类型
      // afterTime、beforeTime
      // afterDate、beforeDate
      type: String
    },
    disableTime: {
      // 禁用时间戳
      type: Number
    },
    disabledDate: {}
  },
  setup(props, ctx) {
    const momentValue = computed(() => {
      let value = props.value ? moment(props.value) : null;
      return value;
    });

    const handleChange = date => {
      date = date ? date.valueOf() : null;
      ctx.emit("input", date);
      ctx.emit("change", date);
    };

    // 禁用日期
    const handleDisableDate = current => {
      // startOf会改变原始值、所以不可公用
      const disableTimeValue = props.disableTime
        ? moment(props.disableTime)
        : moment();

      if (props.disableType === "afterTime") {
        const day = disableTimeValue.endOf("day");
        return current && current > day;
      } else if (props.disableType === "beforeTime") {
        const day = disableTimeValue.startOf("day");
        return current && current < day;
      } else {
        return null;
      }
    };

    // 禁用时间
    const handleDisabledDateTime = current => {
      if (!current) {
        current = moment();
      }

      let hours = [];
      let minutes = [];

      const disableTimeValue = props.disableTime
        ? moment(props.disableTime)
        : moment();

      if (current.format("YYMMDD") === disableTimeValue.format("YYMMDD")) {
        let nowHour = Number(disableTimeValue.format("HH"));

        // 禁用小时
        if (props.disableType === "afterTime") {
          // 禁用之后
          for (let i = nowHour + 1; i < 24; i++) {
            hours.push(i);
          }
        } else if (props.disableType === "beforeTime") {
          // 禁用之前
          for (let i = nowHour - 1; i >= 0; i--) {
            hours.push(i);
          }
        }

        // 禁用分钟
        if (current.format("HH") === disableTimeValue.format("HH")) {
          let nowMinute = Number(disableTimeValue.format("mm"));
          if (props.disableType === "afterTime") {
            for (let i = nowMinute + 1; i < 60; i++) {
              minutes.push(i);
            }
          } else if (props.disableType === "beforeTime") {
            for (let i = nowMinute - 1; i >= 0; i--) {
              minutes.push(i);
            }
          }
        }
      }

      return {
        disabledHours: () => hours,
        disabledMinutes: () => minutes
      };
    };

    return {
      handleChange,
      momentValue,
      handleDisableDate,
      handleDisabledDateTime
    };
  }
});
</script>
