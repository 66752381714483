import { omit } from "lodash-es";

const objectConverter = convertMethod => (obj, omits) => {
  const rest = omit(obj, omits);

  omits.forEach(key => {
    rest[key] = convertMethod(obj[key]);
  });

  return rest;
};

export default objectConverter;
