import Layout from "@/layout";

const asyncRoutes = [
  {
    path: "/customer",
    name: "Customer",
    component: Layout,
    redirect: "/customer/enterprise",
    meta: {
      title: "客户管理",
      icon: "iconkehuguanliweixuanze"
    },
    children: [
      {
        path: "enterprise",
        name: "Enterprise",
        component: () => import("@/views/Customer/Enterprise/Main.vue"),
        meta: { title: "企业信息", keepAlive: true }
      },
      {
        path: "enterprise-detail",
        name: "EnterpriseDetail",
        component: () => import("../views/Customer/Detail/Main.vue"),
        meta: { title: "账户明细", selectKeys: "enterprise", keepAlive: true },
        hidden: true
      }
    ]
  },
  {
    path: "/oil",
    name: "Oil",
    component: Layout,
    redirect: "/oil/enterprise-oil-account",
    meta: {
      title: "加油管理",
      icon: "iconjiayouguanli-weixuanze"
    },
    children: [
      {
        path: "enterprise-oil-account",
        name: "EnterpriseOilAccount",
        component: () => import("@/views/Oil/EnterpriseOilAccount/Main.vue"),
        meta: { title: "企业油卡账户", keepAlive: true }
      },
      {
        path: "driver-oil-account",
        name: "DriverOilAccount",
        component: () => import("@/views/Oil/DriverOilAccount/Main.vue"),
        meta: { title: "司机油卡账户", keepAlive: true }
      },
      {
        path: "driver-oil-account-detail",
        name: "DriverOilAccountDetail",
        component: () => import("@/views/Oil/DriverOilAccountDetail/Main.vue"),
        meta: {
          title: "司机账户详情",
          selectKeys: "driver-oil-account"
        },
        hidden: true
      },
      {
        path: "oil-account-detail",
        name: "OilAccountDetail",
        component: () => import("@/views/Oil/OilAccountDetail/Main.vue"),
        meta: {
          title: "账户详情",
          selectKeys: "enterprise-oil-account"
        },
        hidden: true
      },
      {
        path: "oil-recharge-record",
        name: "OilRechargeRecord",
        component: () => import("@/views/Oil/OilRechargeRecord/Main.vue"),
        meta: {
          title: "充值记录",
          selectKeys: "enterprise-oil-account"
        },
        hidden: true
      },
      {
        path: "driver",
        name: "Driver",
        component: () => import("@/views/Oil/Driver/Main.vue"),
        meta: {
          title: "查看司机",
          selectKeys: "enterprise-oil-account"
        },
        hidden: true
      },
      {
        path: "driver-detail",
        name: "DriverDetail",
        component: () => import("@/views/Oil/DriverDetail/Main.vue"),
        meta: {
          title: "司机详情",
          selectKeys: "enterprise-oil-account"
        },
        hidden: true
      },
      {
        path: "consumer-orders",
        name: "ConsumerOrders",
        component: () => import("@/views/Oil/ConsumerOrders/Main.vue"),
        meta: {
          title: "消费订单"
        }
      },
      {
        path: "consumer-orders-detail",
        name: "ConsumerOrdersDetail",
        component: () => import("@/views/Oil/ConsumerOrdersDetail/Main.vue"),
        meta: {
          title: "订单详情",
          selectKeys: "consumer-orders"
        },
        hidden: true
      },
      {
        path: "transfer-record",
        name: "TransferRecord",
        component: () => import("@/views/Oil/TransferRecord/Main.vue"),
        meta: {
          title: "划拨记录"
        }
      }
    ]
  },
  {
    path: "/financial",
    name: "Financial",
    redirect: "/financial/account",
    component: Layout,
    meta: {
      title: "财务管理",
      icon: "iconcaiwuguanliweixuanze"
    },
    children: [
      {
        path: "account",
        name: "Account",
        component: () => import("@/views/Financial/Account/Main.vue"),
        meta: { title: "账户管理", keepAlive: true }
      },
      {
        path: "account-detail",
        name: "AccountDetail",
        component: () => import("@/views/Financial/AccountDetail/Main.vue"),
        meta: { title: "账户详情", selectKeys: "account", keepAlive: true },
        hidden: true
      },
      {
        path: "recharge",
        name: "Recharge",
        component: () => import("@/views/Financial/Recharge/Main.vue"),
        meta: { title: "充值", selectKeys: "account", keepAlive: true },
        hidden: true
      },
      {
        path: "recharge-withdraw",
        name: "RechargeWithDraw",
        component: () => import("@/views/Financial/RechargeWithDraw/Main.vue"),
        meta: { title: "充值提现", keepAlive: true }
      },
      {
        path: "expense-card",
        name: "ExpenseCard",
        component: () => import("@/views/Financial/ExpenseCard/Main.vue"),
        meta: { title: "消费卡管理", keepAlive: true }
      },
      {
        path: "invoice",
        name: "Invoice",
        component: () => import("@/views/Financial/Invoice/Main.vue"),
        meta: { title: "发票管理", keepAlive: true }
      }
    ]
  },
  {
    path: "/contract",
    name: "Contract",
    component: Layout,
    redirect: "/contract/service-contract",
    meta: {
      title: "合同管理",
      icon: "iconhetongguanli-weidianliang"
    },
    children: [
      {
        path: "service-contract",
        name: "ServiceContract",
        component: () => import("@/views/Contract/ServiceContract/Main.vue"),
        meta: { title: "服务合同" }
      },
      {
        path: "add-contract",
        name: "AddContract",
        component: () => import("@/views/Contract/AddContract/Main.vue"),
        meta: {
          title: "新建合同模板",
          selectKeys: "service-contract"
        },
        hidden: true
      }
    ]
  },
  {
    path: "/etc",
    name: "ETC",
    component: Layout,
    redirect: "/etc/cars-reference",
    meta: {
      title: "ETC服务",
      icon: "iconETCfuwu-weidianliang"
    },
    children: [
      {
        path: "cars-reference",
        name: "CarsReference",
        component: () => import("@/views/ETC/CarsReference/Main.vue"),
        meta: { title: "车辆备案", keepAlive: true }
      },
      {
        path: "application-records",
        name: "ApplicationRecords",
        component: () => import("@/views/ETC/ApplicationRecords/Main.vue"),
        meta: { title: "申请记录", keepAlive: true }
      },
      {
        path: "manual-sync",
        name: "ManualSync",
        component: () => import("@/views/ETC/ManualSync/Main.vue"),
        meta: { title: "发票同步", keepAlive: true }
      }
    ]
  },
  {
    path: "/order",
    name: "Order",
    component: Layout,
    redirect: "/order/service-order",
    meta: {
      title: "订单管理",
      icon: "icondingdanguanliweixuanze"
    },
    children: [
      {
        path: "service-order",
        name: "ServiceOrder",
        component: () => import("@/views/Order/ServiceOrder/Main.vue"),
        meta: { title: "服务订单", keepAlive: true }
      }
    ]
  },
  {
    path: "/system",
    name: "System",
    component: Layout,
    redirect: "/system/service",
    meta: {
      title: "系统设置",
      icon: "iconxitongshezhiweixuanze"
    },
    children: [
      {
        path: "service",
        name: "Service",
        component: () => import("@/views/System/Service/Main.vue"),
        meta: { title: "服务管理", keepAlive: true }
      },
      {
        path: "data-dictionaries",
        name: "DataDictionaries",
        component: () => import("@/views/System/DataDictionaries/Main.vue"),
        meta: { title: "数据字典" }
      },
      {
        path: "finance-service",
        name: "FinanceService",
        component: () => import("@/views/System/FinanceService/Main.vue"),
        meta: { title: "财税计算服务" }
      },
      {
        path: "add-tax-source",
        name: "AddTaxSource",
        component: () => import("@/views/System/AddTaxSource/Main.vue"),
        meta: { title: "添加税源地", selectKeys: "finance-service" },
        hidden: true
      },
      {
        path: "account-number",
        name: "AccountNumber",
        component: () => import("@/views/System/AccountNumber/Main.vue"),
        meta: { title: "账号管理" }
      },
      {
        path: "authority",
        name: "Authority",
        component: () => import("@/views/System/Authority/Main.vue"),
        meta: { title: "角色权限管理" }
      }
    ]
  },
  {
    path: "/:catchAll(.*)",
    name: "*404",
    redirect: "/error",
    hidden: true
  }
];
export default asyncRoutes;
