import ImgPreview from "./index";
import { isClient } from "@wlhy-web-lib/shared";

import { createVNode, render } from "vue";

let instance = null;
export function createImgPreview(options) {
  if (!isClient) return;
  const { imageList, show = true, index = 0 } = options;

  const propsData = {};
  const container = document.createElement("div");
  propsData.imageList = imageList;
  propsData.show = show;
  propsData.index = index;

  instance = createVNode(ImgPreview, propsData);
  render(instance, container);
  document.body.appendChild(container);
}
