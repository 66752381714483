<template>
  <div
    :style="{
      height: height + 'px',
      lineHeight: height + 'px',
      display: 'inline-block'
    }"
  >
    <svg :style="{ height: height, width: width }" aria-hidden="true">
      <use :xlink:href="`#${name}`"></use>
    </svg>
  </div>
</template>
<script>
export default {
  name: "Icon",
  props: {
    svgClass: String,
    name: String,
    height: { type: String, default: "14" },
    width: { type: String, default: "14" }
  }
};
</script>
<style lang="less" scoped></style>
