import Deferred from "@lenic/deferred";

const promiseWrapper = arg => {
  return new Promise((resolve, reject) => {
    try {
      resolve(arg);
    } catch (e) {
      reject(e);
    }
  });
};

export const seqence = (concurrent = 1) => {
  const queue = [];
  const processState = new Array(concurrent).fill(false);

  const exec = () => {
    const vacantIndex = processState.findIndex(v => !v);
    if (vacantIndex < 0) return;

    const item = queue.shift();
    if (!item) return;

    processState[vacantIndex] = true;
    const endProcessingFunc = () => {
      processState[vacantIndex] = false;
      exec();
    };

    const { next, defer } = item;
    promiseWrapper(next())
      .then(defer.resolve, defer.reject)
      .then(endProcessingFunc, endProcessingFunc);
  };

  return next => {
    const defer = new Deferred();

    queue.push({ next, defer });
    exec();

    return defer.promise;
  };
};
